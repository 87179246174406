import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useAppContext } from './AppContext';


function RemoveCarDialog({ handleClose, open, currentCar, clearCar }) {

    const { token } = useAppContext()

    const handleSingleDelete = async () => {
        try {            
            await fetch(`${process.env.REACT_APP_API_URL}/cars/${currentCar}`, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': `${token}`,
                }),
            });
            handleClose()
            clearCar()
        } catch (error) {
            console.log("error ao remover o carro")
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-delete-single-title"
            aria-describedby="alert-dialog-delete-single-description"
        >
            <DialogTitle id="alert-dialog-delete-single-title">
                {"Deseja remover o carro?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-delete-single-description">
                    Essa ação irá remover o carro da base de dados e não poderá ser desfeita.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="contained" 
                    color="error"
                    onClick={handleClose}>
                        Cancelar
                    </Button>
                <Button 
                    variant="contained" 
                    onClick={handleSingleDelete}>
                    Sim
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveCarDialog