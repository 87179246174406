import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppContext } from './AppContext';

export default function TokenFormDialog({ open, handleClose }) {


    const {
        token,
        handleChangeToken,
    } = useAppContext()

    const [currentToken, setCurrentToken] = useState(token)

    function saveTokenHandle() {
        handleClose()
        handleChangeToken(currentToken)
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Cadastrar Token</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="token"
                        label="Token"
                        fullWidth
                        variant="standard"
                        onChange={setCurrentToken}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={saveTokenHandle}>Salvar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
