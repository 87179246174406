import React, { useContext, useState, useEffect } from 'react';

const AppContext = React.createContext(null)

export function useAppContext() {
    return useContext(AppContext)
}

export function AppProviver({ children }) {

    const [cars, setCars] = useState([])


    const [km1, setKm1] = useState(localStorage.getItem("km1") || 0);
    const [km2, setKm2] = useState(localStorage.getItem("km2") || 0);
    const [price1, setPrice1] = useState(localStorage.getItem("price1") || 0);
    const [price2, setPrice2] = useState(localStorage.getItem("price2") || 0);
    const [year1, setYear1] = useState(localStorage.getItem("year1") || 0);
    const [year2, setYear2] = useState(localStorage.getItem("year2") || 0);
    
    const currentSource = localStorage.getItem("source")
    const [source, setSource] = useState(typeof currentSource === 'string' ? currentSource.split(',') : currentSource || []);

    const handleKm1Change = (event) => {
        setKm1(event.target.value);
        localStorage.setItem('km1', event.target.value)
    };

    const handleKm2Change = (event) => {
        setKm2(event.target.value);
        localStorage.setItem('km2', event.target.value)
    };

    const handlePrice1Change = (event) => {
        setPrice1(event.target.value);
        localStorage.setItem('price1', event.target.value)
    };

    const handlePrice2Change = (event) => {
        setPrice2(event.target.value);
        localStorage.setItem('price2', event.target.value)
    };

    const handleYear1Change = (event) => {
        setYear1(event.target.value);
        localStorage.setItem('year1', event.target.value)
    };

    const handleYear2Change = (event) => {
        setYear2(event.target.value);
        localStorage.setItem('year2', event.target.value)
    };

    const handleSourceChange = (event) => {
            const {
              target: { value },
            } = event;
            setSource(
              typeof value === 'string' ? value.split(',') : value || [],
            );

            localStorage.setItem('source', event.target.value)
    };

    const [token, setToken] = useState(localStorage.getItem("token") || "");

    const handleChangeToken = (event) => {
        setToken(event.target.value)
        localStorage.setItem('token', event.target.value)
    }

    useEffect(() => {
        async function getConfig() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/me/config`)
                const res = await fetch(url, {
                    headers: new Headers({
                        'Authorization': `${token}`,
                    }),
                });
                const json = await res.json();
                return json
            } catch (error) {
                throw error
            }
        }

        if (token !== "") {
            getConfig().then(config => {
                console.log(config)

                const {
                    km_start, 
                    km_end, 
                    price_start, 
                    price_end,
                    year_start,
                    year_end,
                    sources
                } = config

                setKm1(km_start)
                setKm2(km_end)
                setPrice1(price_start)
                setPrice2(price_end)
                setYear1(year_start)
                setYear2(year_end)
                setSource(sources || [])

                localStorage.setItem('km1', km_start)
                localStorage.setItem('km2', km_end)
                localStorage.setItem('price1', price_start)
                localStorage.setItem('price2', price_end)
                localStorage.setItem('year1', year_start)
                localStorage.setItem('year2', year_end)
                localStorage.setItem('source', sources.join(","))

            }).catch((error) => {
                console.log(error)
            })
        }
    }, [token]);


    return (
        <AppContext.Provider value={{
            cars, 
            setCars,
            token,
            handleChangeToken,
            km1,
            km2,
            price1,
            price2,
            year1,
            year2,
            source,
            handleKm1Change,
            handleKm2Change,
            handlePrice1Change,
            handlePrice2Change,
            handleYear1Change,
            handleYear2Change,
            handleSourceChange
        }}>
            {children}
        </AppContext.Provider>
    )
}