import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import RemoveCarDialog from './RemoveCarDialog';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function MTable({ cars, setCars }) {

    const handleOpenDeleteSingleDialog = (id, source) => {
        setOpenDeleteDialog(true)
        setSelectedCar(`${id}+${source}`)
    };

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [selectedCar, setSelectedCar] = useState("")

    function hancleClose() {
        setOpenDeleteDialog(false)
    }

    function openLink(link) {
        window.open(link)
    }

    function clearCar() {
        const filretedcars = cars.filter(car => `${car.id}+${car.source}` !== selectedCar )
        setCars(filretedcars)
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {cars && cars.length > 0 && cars.map((row) => (
                            <StyledTableRow key={row.id} >
                                <StyledTableCell onClick={row.link ? () => openLink(row.link) : null} >
                                    <div> <span style={{ fontSize: "18px", fontWeight: "bold" }}>{row.name}</span></div>
                                    <div> <span style={{ fontSize: "14px", fontWeight: "bold" }}>{row.source}</span></div>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{ fontSize: "14", marginRight: "5px" }}>
                                            {row.modelyear && row.modelyear}
                                        </div>
                                        <div style={{ fontSize: "14", marginRight: "5px" }}>
                                            {row.km && row.km + "Km"}
                                        </div>
                                        <div style={{ fontSize: "14", marginRight: "5px" }}>
                                            {row.price && "R$" + row.price}
                                        </div>
                                    </div>
                                </StyledTableCell>
                               { row.link && <StyledTableCell align="left">
                                    <DeleteForeverOutlinedIcon color="error" sx={{ fontSize: 45 }} onClick={() => {
                                        handleOpenDeleteSingleDialog(row.id, row.source)
                                    }} />
                                </StyledTableCell>}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <RemoveCarDialog
                open={openDeleteDialog}
                currentCar={selectedCar}
                handleClose={hancleClose}
                clearCar={clearCar}
            />
        </div>
    )
}

export default MTable;