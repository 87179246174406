import React, { useEffect } from 'react';
import MTable from './Table';
import { useAppContext } from './AppContext';
import MAppBar from './AppBar';

function Main() {

    const { token, cars, setCars } = useAppContext()

    useEffect(() => {
        async function getCars() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/cars`)
                const res = await fetch(url, {
                    headers: new Headers({
                        'Authorization': `${token}`,
                    }),
                });
                const json = await res.json();
                return json
            } catch (error) {
                throw error
            }
        }

        if (token !== "") {
            getCars().then(cars => {

                if (cars && cars.code === 401) {
                    setCars([
                        {
                            id: 1,
                            name: "não autorizado (token inválido)",
                        }
                    ])
                    return
                }
                setCars(cars)
            }).catch((error) => {
                setCars([
                    {
                        id: 1,
                        name: "erro ao conectar com o servidor",
                    }
                ])
            })
        } else {
            setCars([{
                id: 1,
                name: "token inválido",
            }])
        }
    }, [token, setCars]);


    useEffect(() => {
        async function getCars() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/cars`)
                const res = await fetch(url, {
                    headers: new Headers({
                        'Authorization': `${token}`,
                    }),
                });
                const json = await res.json();
                return json
            } catch (error) {
                throw error
            }
        }

        const interval = setInterval(async () => {
            if (token !== "") {
                getCars().then(cars => {
                    if (cars && cars.code === 401) {
                        setCars([
                            {
                                id: 1,
                                name: "não autorizado (token inválido)",
                            }
                        ])
                        return
                    }
                    setCars(cars)
                }).catch((error) => {
                    setCars([
                        {
                            id: 1,
                            name: "erro ao conectar com o servidor",
                        }
                    ])
                })
            } else {
                setCars([{
                    id: 1,
                    name: "token inválido",
                }])
            }
        }, process.env.REACT_APP_REQUEST_INTERVAL);
        return () => clearInterval(interval);
    }, [token, setCars]);

    return (
        <div>
            <MAppBar/>
            <br></br>
            <MTable cars={cars || []} setCars={setCars} />
        </div>
    );
}

export default Main;
