import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useAppContext } from './AppContext';


const sources = [
    'WEBMOTORS',
    'ICARROS',
    'MERCADOLIVRE',
    'OLX'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ConfigurationDialog({
    open,
    handleClose
}) {
    const theme = useTheme();

    const {
        token,
        km1,
        km2,
        price1,
        price2,
        year1,
        year2,
        source,
        handleKm1Change,
        handleKm2Change,
        handlePrice1Change,
        handlePrice2Change,
        handleYear1Change,
        handleYear2Change,
        handleSourceChange
    } = useAppContext()

    const handleFSSave = async () => {
        try {
            await fetch(`${process.env.REACT_APP_API_URL}/me/config`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    "km_start": parseFloat(km1),
                    "km_end": parseFloat(km2),
                    "price_start": parseFloat(price1),
                    "price_end": parseFloat(price2),
                    "year_start": parseFloat(year1),
                    "year_end": parseFloat(year2),
                    "sources": source
                })
            });
            handleClose()
        } catch (error) {
            console.log("error ao salvar configuração")
        }
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Configurações
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleFSSave}>
                        Salvar
                    </Button>
                </Toolbar>
            </AppBar>

            <Container>
                <Grid container sx={{ marginTop: 2 }} spacing={1}>
                    <Grid xs={6} sm={6} item>
                        <TextField
                            id="id-km-1"
                            label="KM Inicio"
                            value={km1}
                            onChange={handleKm1Change}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">km</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid xs={6} sm={6} item>
                        <TextField
                            id="id-km-2"
                            label="KM Fim"
                            value={km2}
                            onChange={handleKm2Change}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">km</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid xs={6} sm={6} item>
                        <TextField
                            id="id-price-1"
                            label="Preço Inicio"
                            value={price1}
                            onChange={handlePrice1Change}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid xs={6} sm={6} item>
                        <TextField
                            id="id-price-2"
                            label="Preço Fim"
                            value={price2}
                            onChange={handlePrice2Change}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>

                    <Grid xs={6} sm={6} item>
                        <TextField
                            id="id-year-1"
                            label="Ano Inicio"
                            value={year1}
                            onChange={handleYear1Change}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={6} sm={6} item>
                        <TextField
                            id="id-year-2"
                            label="Ano Fim"
                            value={year2}
                            onChange={handleYear2Change}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12} sm={12} item>
                        <FormControl fullWidth>
                            <InputLabel id="multiple-fonte-label">Fonte</InputLabel>
                            <Select
                                labelId="multiple-fonte-label"
                                id="multiple-fonte"
                                multiple
                                value={source}
                                onChange={handleSourceChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {sources.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, sources, theme)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    )
}

export default ConfigurationDialog