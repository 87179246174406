import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAppContext } from './AppContext';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TokenFormDialog from './TokenFormDialog';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveAllDialog from './RemoveAllDialog';
import ConfigurationDialog from './ConfigurationDialog';


export default function MAppBar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menu, setMenu] = useState(false);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setMenu(true)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenu(false)
    };

    const { token, handleChangeToken, setCars } = useAppContext()

    const handleLogout = () => {
        handleChangeToken({
            target: {
                value: ""
            }
        })
        setMenu(false)
    };

    const [login, setLogin] = useState(false);

    const handleLoginOpen = () => {
        setLogin(true);
    };

    const handleLoginClose = () => {
        setLogin(false);
    };

    const [openDeleteAllDialog, setOpenDeletellDialog] = useState(false)

    const handleDeleteAllOpen = (event) => {
        setOpenDeletellDialog(true)
        setMenu(false)
    };

    const handleDeleteAllClose = (event) => {
        setOpenDeletellDialog(false)
        setMenu(false)
    };

    const [fsopen, setFsOpen] = useState(false);

    const handleClickFSOpen = () => {
        setFsOpen(true);
        setMenu(false)
    };

    const handleClickFSClose = () => {
        setFsOpen(false);
        setMenu(false)
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        ROBOTCAR
                    </Typography>
                    {!token &&
                        <div>
                            <Button onClick={handleLoginOpen}
                                color="inherit"
                                startIcon={<LoginIcon />}>
                                Entrar
                            </Button>
                            <TokenFormDialog
                                open={login}
                                handleClose={handleLoginClose}
                            />
                        </div>
                    }
                    {!!token && <div>
                        <PersonIcon fontSize='large' onClick={handleOpenMenu} color="inherit"></PersonIcon>
                    </div>
                    }
                </Toolbar>
            </AppBar>

            <ConfigurationDialog
                open={fsopen}
                handleClose={handleClickFSClose}
            />

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={menu}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleClickFSOpen}>
                    <SettingsIcon fontSize='large' />
                    Config.
                </MenuItem>
                <MenuItem onClick={handleDeleteAllOpen}>
                    <DeleteIcon fontSize='large' />
                    Limpar
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <LogoutIcon fontSize='large' />
                    Sair
                </MenuItem>
            </Menu>

            <RemoveAllDialog
                open={openDeleteAllDialog}
                handleClose={handleDeleteAllClose}
                clearCars={() => {
                    setCars([])
                    setMenu(false)
                }}
            />
        </Box>
    );
}
