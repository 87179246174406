import React from 'react';
import { AppProviver } from './components/AppContext';
import Main from './components/Main';

function App() {

    return (
        <AppProviver>
            <Main />
        </AppProviver>
    );
}

export default App;
