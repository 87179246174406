import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useAppContext } from './AppContext';

function RemoveAllDialog({open, handleClose, clearCars}) {

    const { token } = useAppContext()

    const handleCleanAllCars = async () => {
        try {            
            await fetch(`${process.env.REACT_APP_API_URL}/cars`, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': `${token}`,
                }),
            });
            clearCars()
            handleClose()
        } catch (error) {
            console.log("erro ao remover todos os carros")
        }
    };

    return (
        <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Deseja remover todos os carros?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Essa ação limpará todos os carros da base de dados e não poderá ser desfeita.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleCleanAllCars} autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export default RemoveAllDialog